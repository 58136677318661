<template>
  <div class="createNewsItem">
    <LSection class="my-6 k1:my-20">
      <template #body>
        <form
          @submit.prevent="onSubmit"
          ref="form"
          class="mx-auto shadow-2xl p-6 rounded max-w-2xl"
        >
          <h1 class="text-3xl text-gray-800 font-bold text-center mb-6">
            Create an Accord
          </h1>
          <div class="w-full md:w-1/2 lg:w-1/3 mb-6 w-50">
            <label for="title">Title</label>
            <TextInput
              :tabIndex="1"
              name="title"
              placeholder="Title"
              @change="onTitleChange"
            ></TextInput>
          </div>
          <div class="flex gap-6 w-full">
            <div class="w-1/2 mb-6 w-50">
              <label for="start">Start-Date</label>
              <TextInput
                :tabIndex="4"
                type="date"
                name="start"
                placeholder="Start-Date"
                @change="onStartChange"
              ></TextInput>
            </div>

            <div class="w-1/2 mb-6 w-50">
              <label for="End">End-Date </label>
              <span class="text-sm text-grey-100">(Optional)</span>
              <TextInput
                :tabIndex="5"
                type="date"
                name="end"
                placeholder="End-Date"
                @change="onEndChange"
              ></TextInput>
            </div>
          </div>

          <div class="w-full md:w-1/2 lg:w-1/3 mb-6 w-50">
            <label for="countries">Countries</label>
            <TextInput
              :tabIndex="6"
              name="countries"
              placeholder="Countries"
              @change="onCountriesChange"
            ></TextInput>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
            <label for="description">
              Summary
              <span>
                {{descriptionCharactersLeft}}
              </span>
            </label>
            <TextArea
              :tabIndex="7"
              :minLines="3"
              name="description"
              placeholder="Summary"
              @input="onDescriptionChange"
              @change="onDescriptionChange"
            ></TextArea>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
            <label for="contactPersonName">Contact Person Name</label>
            <TextInput
              :tabIndex="8"
              name="contactPersonName"
              placeholder="Contact Person Name"
              @change="onContactPersonNameChange"
            ></TextInput>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
            <label for="contactPersonEmail">Contact Person Email</label>
            <TextInput
              :tabIndex="9"
              type="email"
              name="contactPersonEmail"
              placeholder="Contact Person Email"
              @change="onContactPersonEmailChange"
            ></TextInput>
          </div>
          <div class="w-full">
            <h2 class="text-lg">
              Which principle(s) does your action plan endorse?
            </h2>
          </div>
          <div class="flex gap-x-12 gap-y-2 flex-wrap mb-10 mt-4">
            <div
              v-for="key in Object.keys(principles)"
              :key="key"
              class="flex gap-4 w-1/2"
              @click="() => onPrincipleChange(key)"
            >
              <input
                type="checkbox"
                :name="'principle' + key"
                :checked="newDocument.principles.includes(key)"
              />
              <h2 class="text-sm">{{ principles[key].name }}</h2>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/3 mb-6">
            <label for="actionPlan">Action Plan</label>
            <Markdown
                v-if="newDocument.actionPlan"
                :modelValue="newDocument.actionPlan"
                :tabIndex="10"
                :isEditable="true"
                name="actionPlan"
                placeholder="Action Plan"
                @change="onActionPlanChange"
            ></Markdown>
          </div>

          <div class="w-full md:w-1/2 lg:w-1/3 mb-6 flex gap-4">
            <Button
              class="w-32"
              type="submit"
              :is-primary="true"
              @click="onSubmit()"
            >
              <template #center> Create</template>
            </Button>

            <Button
              class="button secondary"
              :is-primary="false"
              @click="
                $router.push({
                  name: 'organisationAccords',
                  params: { organisationId },
                })
              "
            >
              <template #center> Cancel</template>
            </Button>
          </div>
        </form>
      </template>
    </LSection>
  </div>
</template>

<script>
import { useDocuments } from "@/composables/useDocuments";
import LSection from "@/components/layout/LSection";
import TextInput from "@/components/forms/TextInput";
import TextArea from "@/components/forms/TextArea";
import {ref, reactive, computed} from "vue";
import Button from "../forms/Button.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import principles from "./principles";
import { useDocument } from "@/composables/useDocument";
import Markdown from "@/components/forms/Markdown.vue";

const getDefaultAccords = () =>
  new Object({
    start: '',
    description: '',
    principles: [],
    actionPlan: '',
  });

export default {
  name: "CreateAccords",
  components: {
    Markdown,
    Button,
    LSection,
    TextInput,
    TextArea,
  },
  props: {
    organisationId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const newDocument = reactive(getDefaultAccords());
    const error = ref(null);
    const store = useStore();
    const descriptionMaxCharacters = 150
    const descriptionCharactersLeft = computed(() => {
      let charactersLeft = descriptionMaxCharacters
      if (newDocument.description?.length) charactersLeft = descriptionMaxCharacters - newDocument.description?.length
      return `(${charactersLeft} characters left)`
    })

    const { organisation, loadOrganisation } = useDocument(
      "Organisation",
      props.organisationId
    );
    const { createDocument } = useDocuments("Accord", {
      permissionHostDataType: "Organisation",
      permissionHostDataId: props.organisationId,
    });
    const router = useRouter();

    loadOrganisation();

    const handleFileUpload = (value) => {
        console.log(value)
        // todo: handle action plan with file upload in second step
        // newDocument.file = value;
    };

    const reset = () => {
      Object.keys(newDocument).forEach((key) => delete newDocument[key]);
      Object.assign(newDocument, getDefaultAccords());
    };
    const onSubmit = () => {
      try {
        store.state.UI.message = "";
        newDocument.parentId = organisation.value.repoId;
        createDocument(newDocument).then(() => {
          store.state.UI.message = "Accord created successfully!";
          reset();
          router.push("/organisations/" + props.organisationId + "/accords");
        });
      } catch (err) {
        error.value = err;
      }
    };
    return {
      createDocument,
      descriptionCharactersLeft,
      descriptionMaxCharacters,
      error,
      newDocument,
      onActionPlanChange: value => newDocument.actionPlan = value,
      onCountriesChange: (value) => (newDocument.countries = value),
      onDescriptionChange: (value) => (newDocument.description = value),
      onEndChange: (value) => (newDocument.end = value),
      onPrincipleChange: (key) => {
        const docPrinciples = new Set(newDocument.principles ?? []);
        docPrinciples[newDocument.principles.includes(key) ? "delete" : "add"](
          key
        );
        newDocument.principles = [...docPrinciples];
      },
      onStartChange: (value) => (newDocument.start = value),
      onSubmit,
      onTitleChange: (value) => (newDocument.title = value),
      onContactPersonNameChange: (value) =>
        (newDocument.contactPersonName = value),
      onContactPersonEmailChange: (value) =>
        (newDocument.contactPersonEmail = value),
      principles,
      handleFileUpload,
    };
  },
};
</script>

<style scoped></style>
